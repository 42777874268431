import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { FormHandles } from '@unform/core'
import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import BadgeIsValid from '~/components/BadgeIsValid'
import { Button } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import { FilterSearch } from '~/components/FilterSearch'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { FormGroup, Label, Textarea } from '~/components/unform'
import { useAppInfo } from '~/hooks/useAppInfo'
import * as types from '~/modules/retailMedia/store/product/constants'
import { useAppSelector } from '~/store/hooks'
import { setQuery } from '~/store/modules/filters/actions'

import {
  addProducts,
  deleteAllProductsOnCampaign
} from '../../store/campaign/actions'
import { listProducts } from '../../store/product/actions'
import AlertBrands from '../AlertBrands'
import Product from '../Product'
import ProductsPanelTabs from '../ProductsPanelTabs'

import * as S from './styles'

const ProductsPanel: React.FC<ProductsPanelProps<FormHandles>> = ({
  formRef,
  isRequired,
  description,
  isEdit = false,
  isCollapsible = true,
  Buttons,
  currentProducts = [],
  publisherId
}) => {
  const { isWhiteLabel } = useAppInfo()

  /**
   * Lógica para a funcionalidade principal
   */

  const [bulkProducts, setBulkProducts] = useState<string>('')
  const [activeTab, setActiveTab] = useState<SearchProductBy>('search')

  // Busca de produto
  const dispatch = useDispatch()

  const {
    campaign: {
      products: {
        selectedProducts: newProducts,
        loadingAddBulk,
        rejectedProducts = null
      }
    },
    product: {
      list: { data: searchProducts, loading: listProductsLoading }
    }
  } = useAppSelector(state => state.retailMedia)

  const allProducts = useMemo(
    () => [...currentProducts, ...newProducts],
    [currentProducts, newProducts]
  )

  const { query } = useAppSelector(state => state.filters)

  const selectedSKUS = useMemo(
    () => allProducts.map(item => item.product_sku),
    [allProducts]
  )
  const selectedIDs = useMemo(
    () => allProducts.map(item => item.id),
    [allProducts]
  )
  const itemsDisableds = useMemo(
    () => allProducts.filter(item => item.item_disabled),
    [allProducts]
  )

  const resetFieldQuery = useCallback(() => {
    dispatch(
      setQuery({
        query: { [types.PRODUCT_REDUX_QUERY]: null },
        keyState: types.PRODUCT_REDUX_QUERY
      })
    )
    dispatch(deleteAllProductsOnCampaign(isEdit))
  }, [dispatch, isEdit])

  const filteredQuery = useMemo(
    () => query?.[`${types.PRODUCT_REDUX_QUERY}`] || null,
    [query]
  )

  const queryStringSearchProduct = useMemo(
    () => ({
      quantity: 1000,
      query: filteredQuery,
      publisher_id: publisherId
    }),
    [filteredQuery, publisherId]
  )

  useEffect(() => {
    resetFieldQuery()
  }, [resetFieldQuery])

  useEffect(() => {
    if (
      activeTab === 'search' &&
      filteredQuery &&
      (publisherId || isWhiteLabel)
    ) {
      dispatch(listProducts(queryStringSearchProduct))
    }
  }, [
    activeTab,
    dispatch,
    filteredQuery,
    publisherId,
    queryStringSearchProduct,
    isWhiteLabel
  ])

  const handleDeleteAllProducts = useCallback(
    () => dispatch(deleteAllProductsOnCampaign(isEdit)),
    [dispatch, isEdit]
  )

  const handleChangeProductsBulk = useCallback((e: OnChangeTextAreaData) => {
    const data = e.target.value

    setBulkProducts(data)
  }, [])

  const handleAddProductsBulk = useCallback(() => {
    const queryKey: AddProductsBodyQueryKey = {
      search: 'query',
      product_skus: 'product_skus',
      product_gtins: 'product_gtins',
      product_additional_references: 'product_additional_references',
      product_reference_codes: 'product_reference_codes'
    }

    const body = {
      publisher_id: publisherId,
      page: 0,
      products: bulkProducts,
      isEdit,
      queryKey: queryKey?.[activeTab] || 'query'
    }

    dispatch(addProducts({ body }))

    formRef.current.setFieldValue('bulkProducts', '')
  }, [activeTab, bulkProducts, dispatch, formRef, isEdit, publisherId])

  const iconLeft = useMemo(
    () => <BadgeIsValid isValid={!!allProducts?.length} />,
    [allProducts]
  )

  const handleTabs = useCallback(
    (tabKey: SearchProductBy) => setActiveTab(tabKey),
    []
  )

  return (
    <Panel
      title={t('rm:ProductsPanel.title')}
      description={description}
      isCollapsible={isCollapsible}
      {...(isRequired ? { iconLeft } : {})}
    >
      <S.Header>
        <AlertBrands publisherId={publisherId} />
      </S.Header>

      {/* Lista de pesquisa */}
      <S.ProductsLists>
        <S.ProductsList>
          <S.ProductsListHeader>
            <ProductsPanelTabs
              activeTab={activeTab}
              publisherId={publisherId}
              onChange={handleTabs}
            />

            {activeTab === 'search' && (
              <FilterSearch
                placeholder={t('rm:filter.searchProductsPlaceholder')}
                keyState={`${types.PRODUCT_REDUX_QUERY}`}
                live
                isDebounce
              />
            )}

            <Loading status={listProductsLoading}>
              {t('rm:ProductsPanel.loadingProducts')}...
            </Loading>

            {/* Esta seção permitirá o usuário adicionar vários produtos de uma vez */}
            {activeTab !== 'search' && (
              <S.BulkSection>
                <Loading status={loadingAddBulk}>
                  {t('rm:ProductsPanel.addingProducts')}...
                </Loading>

                {!!rejectedProducts?.length && (
                  <div className="alertsForm" key={`errorOn_${activeTab}`}>
                    <AlertInfo
                      text={t('rm:ProductsPanel.alert.addProductError', {
                        codes: rejectedProducts.join(', ') as string
                      })}
                    />
                  </div>
                )}

                <FormGroup>
                  <Label htmlFor="bulkProducts" isRequired>
                    {t('rm:ProductsPanel.bulkProducts.label')}
                  </Label>
                  <Textarea
                    name="bulkProducts"
                    // eslint-disable-next-line max-len
                    placeholder={t('rm:ProductsPanel.bulkProducts.placeholder')}
                    onChange={handleChangeProductsBulk}
                  />
                </FormGroup>

                <FormGroup className="actionButtons">
                  <Button
                    text={t('common:actions.add')}
                    onClick={handleAddProductsBulk}
                    disabled={!bulkProducts}
                  />
                </FormGroup>
              </S.BulkSection>
            )}
          </S.ProductsListHeader>

          {!listProductsLoading && activeTab === 'search' && filteredQuery && (
            <div>
              {searchProducts?.map(item => (
                <Product
                  hasAction
                  key={item.id}
                  data={item}
                  selectedSKUS={selectedSKUS}
                  selectedIDs={selectedIDs}
                  itemsDisableds={itemsDisableds}
                />
              ))}
            </div>
          )}
        </S.ProductsList>

        {/* Lista de selecionadas */}
        <S.ProductsList>
          <S.ProductsListHeader>
            <S.Heading>
              <S.Title>
                <Trans
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  i18nKey="rm:ProductsPanel.selectedProductsLength"
                  defaults="Produtos selecionados <span>({{count}} produtos)</span>"
                  components={{ span: <span /> }}
                  values={{ count: allProducts.length || 0 }}
                />
              </S.Title>

              {!!allProducts?.length && (
                <Button
                  template="transparent"
                  text={t('c:actions.removeAll')}
                  onClick={handleDeleteAllProducts}
                  size="xsmall"
                />
              )}
            </S.Heading>

            {isEdit && (
              <S.Info>
                <AlertInfo
                  text={t('rm:ProductsPanel.alert.cpcOnEditing')}
                  template="info"
                />
              </S.Info>
            )}
          </S.ProductsListHeader>

          <div>
            {allProducts?.map(item => (
              <Product hasAction key={item.id} data={item} isSelected />
            ))}
          </div>

          {allProducts.length === 0 && (
            <div className="empty">
              <EmptyPage
                title={t('rm:ProductsPanel.empty.title')}
                subtitle={t('rm:ProductsPanel.empty.subtitle')}
                svgPath="photos/orders-empty"
              />
            </div>
          )}
        </S.ProductsList>
      </S.ProductsLists>

      {Buttons && Buttons}
    </Panel>
  )
}

export default ProductsPanel
