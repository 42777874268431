import styled from 'styled-components'

import { device } from '~/styles/device'

export const TabsWrapper = styled.nav`
  --buttonHeight: 2.375rem;
  --buttonHoverBG: var(--white);

  @media ${device.laptop_max} {
    --gutter: 0.25rem;
    --gap: 0.5rem;
    --overflow: scroll;

    > * {
      min-width: 30vw;
      width: 30vw;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media ${device.laptop} {
    --gutter: 0;
    --gap: 1rem;
    --overflow: visible;

    flex-wrap: wrap;
  }

  display: flex;
  overflow: var(--overflow);
  gap: var(--gap);
  max-width: 100vw;
  padding: 0 var(--gutter);
  border-color: var(--primary) !important;

  > a {
    height: var(--buttonHeight);

    &:hover:not(.active) {
      background-color: var(--buttonHoverBG);
      box-shadow: 1px -2px 1rem -0.75rem rgba(0, 0, 0, 0.25);
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: calc(var(--buttonHeight) + 0.25rem);
    }
  }
`
