import { useQuery } from '@tanstack/react-query'

import { LogoStore } from '~/components/LogoStore'
import { isPublisher } from '~/helpers'
import { useGetFetchQuery } from '~/hooks/useGetFetchQuery'

import { getAdvertiserService } from '../../services/advertiser'
import { getPublisherService } from '../../services/publisher'
import BalanceByPublisher from '../BalanceByPublisher'

/**
 * Component to render account information of a publisher or advertiser.
 * Shows name, logo and wallet balance.
 */
const AccountInfo: React.FC<AccountInfoProps> = ({
  id,
  retailMediaAccountType
}) => {
  const queryKey = ['getAccountInfo', id]

  const cachedData = useGetFetchQuery<
    GetAdvertiserResponse | GetPublisherResponse
  >(queryKey)

  const { data: response, isPending: loading } = useQuery({
    queryKey: ['getAccountInfo', id],
    enabled: !!id && !cachedData,
    queryFn: async () => {
      type Services = {
        [key in RetailMediaAccountTypes]:
          | typeof getAdvertiserService
          | typeof getPublisherService
      }

      const services: Services = {
        publisher: getAdvertiserService,
        advertiser: getPublisherService
      }

      const res = await services[retailMediaAccountType]({
        url: { id },
        params: { account_info: true }
      })

      return res.data
    }
  })

  const data = cachedData || response

  return (
    <aside className="d-flex gap-2 flex-row-reverse">
      <section className="d-flex flex-column align-items-start">
        <header>
          <strong className="fs-6">{data?.name}</strong>
        </header>
        <BalanceByPublisher
          id={id}
          idAccountType={isPublisher() ? 'grocery' : 'industry'}
          showWalletLink={false}
        />
      </section>

      <section>
        {!loading && (
          <LogoStore
            size="medium"
            linkLogo={data?.account_logo}
            alt={data?.name}
            backgroundLogo={data?.account_theme?.header_color || 'transparent'}
          />
        )}
      </section>
    </aside>
  )
}

export default AccountInfo
