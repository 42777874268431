import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import EmptyPage from '~/components/empty-page'
import { Filters } from '~/components/Filters'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import LoadingPopUp from '~/components/LoadingPopUp'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { isAdvertiser, isPublisher, requestRetailMediaAccount } from '~/helpers'
import { useAlert } from '~/hooks/useAlert'
import { useAppInfo } from '~/hooks/useAppInfo'
import { Footer } from '~/pages/products/list/styles'
import { useAppSelector } from '~/store/hooks'

import { listCheckingAccountService } from '../../services/checking-account'
import actions from '../../store/checkingAccount/actions'
import { retailMediaWallets } from '../../store/common/filters'
import { schema } from './schema'

const CheckingAccountTable = () => {
  const { isWhiteLabel, hasRecharge } = useAppInfo()

  const dispatch = useDispatch()

  const {
    checkingAccount: {
      list: {
        page,
        perPage,
        sortDirection,
        sortKey,
        sortDirectionDefault,
        sortKeyDefault
      }
    }
  } = useAppSelector(state => state.retailMedia)

  const {
    retailMedia: { queryFilterWallet }
  } = useAppSelector(state => state.filters)

  const params = useMemo(() => {
    const keyAccount = requestRetailMediaAccount
    const key = sortKey || sortKeyDefault

    const orderField =
      (sortKey || sortKeyDefault) === 'name' ? `${keyAccount}_name` : key

    const orderType = sortDirection || sortDirectionDefault

    return {
      page,
      quantity: perPage,
      account_info: true,
      name: queryFilterWallet as string | null,
      orderField,
      orderType
    }
  }, [
    page,
    perPage,
    queryFilterWallet,
    sortDirection,
    sortDirectionDefault,
    sortKey,
    sortKeyDefault
  ])

  const {
    data: checkingAccountList,
    error,
    isFetching
  } = useQuery({
    queryKey: [
      'checking-account',
      page,
      perPage,
      queryFilterWallet,
      sortKey,
      sortDirection
    ],
    queryFn: async () => {
      const response = await listCheckingAccountService(params)

      return response.data
    }
  })

  const [AlertOnError] = useAlert(
    error &&
      (error?.response?.data?.messages?.[0] ||
        t('common:messages.errorOccurred.message'))
  )

  const handlePagination = useCallback(
    (page: number) => {
      dispatch(actions.setPage(page))
    },
    [dispatch]
  )

  const handleItemsPerPageChange = useCallback(
    ({ value }: { value: number }) => {
      dispatch(actions.setPerPage(value))
    },
    [dispatch]
  )

  const handleSortList = useCallback(
    (data: OnSortProps) => {
      dispatch(actions.setSorting(data))
    },
    [dispatch]
  )

  return (
    <>
      {(isPublisher() || (!isWhiteLabel && isAdvertiser())) && (
        <Filters {...retailMediaWallets} />
      )}

      <Loading status={!checkingAccountList && isFetching}>
        {t('common:actions.loadingData')}...
      </Loading>

      <LoadingPopUp isActive={isFetching} />

      <AlertOnError />

      {checkingAccountList?.total === 0 && (
        <EmptyPage
          title={t('common:table.empty')}
          subtitle={t('common:table.emptyFiltered')}
          titleButton={t('common:words.clearFilters')}
          svgPath="photos/orders-empty"
        />
      )}

      {checkingAccountList?.total > 0 && (
        <>
          <Panel noPaddingBody isLoading={!checkingAccountList}>
            {!!checkingAccountList && (
              <Table
                schema={schema({ hasRecharge })}
                data={checkingAccountList?.data}
                total={checkingAccountList?.total}
                activeSortDirection={sortDirection || sortDirectionDefault}
                activeSortKey={sortKey || sortKeyDefault}
                onSort={handleSortList}
                sideBorder
                smallerRowPadding
              />
            )}
          </Panel>

          {checkingAccountList?.pages > 1 && (
            <Footer>
              <ListPagination
                total={checkingAccountList?.total}
                label={t(
                  `rm:${checkingAccountList?.total > 1 ? 'wallets' : 'wallet'}`
                )}
                currentPage={page}
                itemsPerPage={perPage}
                menuPlacement="top"
                onClickPagination={handlePagination}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </Footer>
          )}
        </>
      )}
    </>
  )
}
export default CheckingAccountTable
