import React, { useMemo } from 'react'
import { TfiWallet } from 'react-icons/tfi'
import { NavLink } from 'react-router-dom'

import AlertInfo from '~/components/AlertInfo'
import { ButtonNavLink } from '~/components/Buttons'
import { Spinner } from '~/components/Spinner'
import { Badge } from '~/components/UI/Badge'
import { formatMoney } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useFetchSWR from '~/hooks/useFetchSWR'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'

import { checkingAccount } from '../../services/industry'

import * as S from './styles'

type BalanceProps = {
  id: string
  idAccountType: AccountTypes
  showWalletLink?: boolean
  showRechargeLink?: boolean
  showZeroAlert?: boolean
}

const BalanceByPublisher: React.FC<BalanceProps> = ({
  id,
  idAccountType,
  showWalletLink = true,
  showRechargeLink = true,
  showZeroAlert = false
}) => {
  const { t } = useAPPTranslation()
  const { hasRecharge } = useAppInfo()
  // O BFF casa ID do token com ID enviado para trazer a carteira
  const paramKeyName = idAccountType === 'grocery' ? 'advertiser' : 'publisher'

  const paramKey = `${paramKeyName}_id` as 'advertiser_id' | 'publisher_id'

  const balanceURL = useMemo(() => {
    return id
      ? `${checkingAccount.baseURL}?${paramKey}=${id}&count=false`
      : `${checkingAccount.baseURL}?count=false`
  }, [id, paramKey])

  const {
    data,
    isValidating,
    error
  }: { data: GetCheckingAccountResponse; isValidating: boolean; error: any } =
    useFetchSWR({
      url: balanceURL,
      refreshInterval: 60000,
      revalidateOnFocus: true
    })

  const balance = useMemo(() => {
    const newData = id
      ? data?.filter(item => item[paramKey] === id)?.[0]
      : data?.[0]
    return formatMoney(newData?.balance || 0)
  }, [data, id, paramKey])

  const balanceValue = useMemo(() => {
    const newData = id
      ? data?.filter(item => item[paramKey] === id)?.[0]
      : data?.[0]
    return formatMoney(newData?.balance || 0)
  }, [data, id, paramKey])

  const walletId = useMemo(() => {
    const newData = id
      ? data?.filter(item => item[paramKey] === id)?.[0]
      : data?.[0]
    return newData?.id
  }, [data, id, paramKey])

  return (
    <S.Container>
      {error && (
        <Badge iconLeft={<TfiWallet />} style={{ height: '22px' }}>
          {t('rm:wallet.balance.loading.error')}
        </Badge>
      )}

      {!error && (
        <>
          <NavLink to={`/retail-media/wallet/${walletId}?rmid=${id}`}>
            <Badge
              iconLeft={<TfiWallet />}
              style={{ height: '22px' }}
              {...(!showWalletLink && { className: 'me-0' })}
            >
              {t('rm:wallet.campaign.walletBalance')}: {balance}{' '}
              {isValidating && <Spinner />}
            </Badge>
          </NavLink>

          {showWalletLink && (
            <ButtonNavLink
              to={`/retail-media/wallet/${walletId}?rmid=${id}`}
              size="xsmall"
              text={t('rm:wallet.campaign.walletLink')}
              template="transparentPrimary"
            />
          )}

          {hasRecharge && showRechargeLink && idAccountType === 'industry' && (
            <ButtonNavLink
              to={`/retail-media/wallet/${walletId}/recharge?rmid=${id}`}
              size="xsmall"
              text={t('rm:wallet.table.actions.addCredit')}
              template="transparentPrimary"
              target="_blank"
              rel="noreferrer"
            />
          )}
        </>
      )}

      {showZeroAlert && !isNaN(balanceValue) && balanceValue <= 0 && (
        <footer style={{ flex: '1 1 100%' }}>
          <AlertInfo template="danger">
            {t('rm:wallet.balance.empty')}{' '}
            <NavLink to={`/retail-media/wallet/${walletId}?rmid=${id}`}>
              {t('rm:wallet')}
            </NavLink>
            .
          </AlertInfo>
        </footer>
      )}
    </S.Container>
  )
}

export default BalanceByPublisher
