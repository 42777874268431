import { useCallback, useMemo, useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import Panel from '~/components/Panel'
import { isPublisher } from '~/helpers'
import useModal from '~/hooks/useModal'
import { useAppSelector } from '~/store/hooks'

import MediaReviewTable from '../_tables/MediaReviewTable'
import { listAdsResultsV2Service } from '../../services/ad/result/v2'
import {
  handleStatusCampaign,
  handleStatusLocal
} from '../../store/grocery/campaign/actions'
import { Alerts } from './_components/Alerts'
import { ModalConfirmations } from './_components/Modal'
import { RejectMessage } from './_components/RejectMessage'

import * as S from './styles'

interface Props {
  status: CampaignStatus
  message: string
}

type CallbackData = {
  status: string
  status_history: {
    status: string
    note: string
  }[]
}

export const ReviewBar = ({ status, message }: Props) => {
  const [contentModal, setContentModal] = useState<ContentModal | null>(null)

  const [Modal, showConfirmationModal, toggleConfirmationModal, setIsShowing] =
    useModal()

  const dispatch = useDispatch()

  const {
    campaign: {
      item: { data }
    },
    grocery: {
      campaign: {
        patch: { loading: loadingPatch }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  // Consts

  const typeHasReview = [
    'banner',
    'sponsored_brand',
    'digital_signage'
  ].includes(data?.adType)

  const showReviewActions = useMemo(
    () => isPublisher() && typeHasReview && status === 'pending_review',
    [status, typeHasReview]
  )

  const isRejected = useMemo(() => status === 'rejected', [status])

  // Handles

  const handleReject = () => {
    setContentModal({
      titleModal: t('rm:reviewCampaign.reject.title.modal'),
      labelMessage: t('rm:reviewCampaign.reject.label.message'),
      subtitletLabelMessage: t(
        'rm:reviewCampaign.reject.subtitle.label.message'
      ),
      textButton: t('rm:button.Reject'),
      templateButton: 'danger',
      status: 'rejected',
      action: 'review',
      noteIsRequired: true,
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const handleApprove = () => {
    setContentModal({
      titleModal: t('rm:reviewCampaign.approve.title.modal'),
      titleAlertMessage: t('rm:reviewCampaign.approve.title.alert.message'),
      alertMessage: t('rm:reviewCampaign.approve.alert.message'),
      textButton: t('rm:button.Approve'),
      templateButton: 'success',
      status: 'approved',
      action: 'review',
      hasCancelButton: true
    })

    toggleConfirmationModal()
  }

  const queryClient = useQueryClient()

  const handleSubmit = useCallback(
    (dataForm: {
      status: CampaignStatus
      action: CampaignActions
      note: string
    }) => {
      const callbackData = {
        status: dataForm?.status,
        status_history: [
          {
            status: dataForm?.status,
            note: dataForm?.note
          }
        ]
      }

      const callbackDispatch = (data: CallbackData) => {
        setTimeout(() => {
          dispatch(handleStatusLocal(data))

          queryClient.invalidateQueries({
            queryKey: ['get-ads']
          })

          setIsShowing(false)
        }, 2000)

        dispatch(handleStatusLocal(data))
      }

      dispatch(
        handleStatusCampaign({
          id: data?.id,
          body: { ...dataForm },
          onSuccess: callbackDispatch(callbackData)
        })
      )
    },
    [data?.id, dispatch, queryClient, setIsShowing]
  )

  /**
   * Const show section
   */
  const { id: campaignId } = useParams()

  const params = useMemo(() => {
    const data = {
      campaign_id: campaignId,
      page: 1,
      quantity: 1,
      ad_type: 'banner',
      ad_status: 'pending_review',
      // Ainda não temos suporte para 2 queries no <Filters />
      account_info: false
    } as QueryParamsAds

    return data
  }, [campaignId])

  const queryKey = ['get-ads', 'pending_review', 'single', campaignId]

  const { data: hasAdInRewview } = useQuery({
    enabled: !!campaignId,
    queryKey,
    refetchInterval: 60000,
    queryFn: async () => {
      const response = await listAdsResultsV2Service({ params })

      return response?.data?.total
    }
  })

  const showSection = useMemo(
    () => showReviewActions || !!hasAdInRewview,
    [hasAdInRewview, showReviewActions]
  )

  return (
    showSection && (
      <Panel className="border-warning">
        <S.WrapperStatus>
          <S.TitleStrong>{t('rm:CampaignReview')}</S.TitleStrong>
        </S.WrapperStatus>

        {isRejected && <RejectMessage textMessage={message} />}

        {showReviewActions && (
          <>
            <Alerts status={status} />

            <S.WrapperInteractions>
              <Button
                template="danger"
                onClick={handleReject}
                loading={loadingPatch}
                text={t('rm:button.RejectCampaign')}
                iconLeft={<FiX />}
              />

              <Button
                template="success"
                onClick={handleApprove}
                loading={loadingPatch}
                text={t('rm:button.ApproveCampaign')}
                iconLeft={<FiCheck />}
              />
            </S.WrapperInteractions>
          </>
        )}

        <MediaReviewTable />

        <ModalConfirmations
          Modal={Modal}
          setIsShowing={setIsShowing}
          showConfirmationModal={showConfirmationModal}
          handleSubmit={handleSubmit}
          toggleConfirmationModal={toggleConfirmationModal}
          contentModal={contentModal}
        />
      </Panel>
    )
  )
}
