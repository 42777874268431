import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import AdsTable from '~/modules/retailMedia/components/_tables/AdsTable'
import CampaignCategoryTable from '~/modules/retailMedia/components/_tables/CampaignCategoriesTable'
import CampaignKeyWordTable from '~/modules/retailMedia/components/_tables/CampaignKeyWordTable'
import CampaignPlacementsTable from '~/modules/retailMedia/components/_tables/CampaignPlacementsTable'
import ProductsTable from '~/modules/retailMedia/components/_tables/ProductsTable'
import AudiencePanel from '~/modules/retailMedia/components/AudiencePanel'
import DestinationUrls from '~/modules/retailMedia/components/BannersTable/DestinationUrls'
import Budget from '~/modules/retailMedia/components/Budget'
import DailyHistoryTable from '~/modules/retailMedia/components/DailyHistoryTable'
import { InfoView } from '~/modules/retailMedia/components/InfoView'
import { StatusBar } from '~/modules/retailMedia/components/StatusBar'
import StatusHistory from '~/modules/retailMedia/components/StatusHistory'

import MetricsCampaignView from '../Metrics'
import DetailsSponsoredBrands from '../SponsoredBrandsDetails'

const ViewSponsoredBrandCampaign: React.FC<ViewCampaignProps> = ({
  data,
  loading
}) => {
  const hasProducts = !!data.products?.length
  const hasKeywords =
    data?.targetingType === 'keyword' || !!data.keywords?.length
  const hasCategories =
    data?.targetingType === 'category' || !!data.banners?.categories?.length
  const hasPlacements = !!data.settings?.has_placements
  const hasAudience = !!data.audienceId

  const statusBarOnTop = ['pending_review', 'rejected'].includes(data.status)

  return (
    <>
      {statusBarOnTop && (
        <StatusBar
          status={data?.status}
          message={
            data?.status_history?.length > 0
              ? data?.status_history[0]?.note
              : ''
          }
        />
      )}

      <MetricsCampaignView />

      <Loading status={loading}>Carregando dados das campanhas...</Loading>

      <AdsTable campaignAdType={data.adType} />

      <hr />

      <DailyHistoryTable
        campaignType={data.adType}
        campaignStartDate={data.raw.start_at}
      />

      {hasProducts && (
        <>
          <hr />
          <Panel title="Produtos selecionados" isCollapsible noPaddingBody>
            <ProductsTable
              campaignId={data.id}
              adType={data.adType}
              publisherId={data.publisher.id}
            />
          </Panel>
        </>
      )}

      {hasKeywords && (
        <>
          <hr />
          <CampaignKeyWordTable campaignId={data.id} />
        </>
      )}

      {hasCategories && (
        <>
          <hr />
          <CampaignCategoryTable
            campaignId={data.id}
            publisherId={data.publisher.id}
          />
        </>
      )}

      {hasPlacements && (
        <>
          <hr />
          <CampaignPlacementsTable campaignId={data.id} />
        </>
      )}

      <hr />

      <Budget data={data} loading={loading} />

      {hasAudience && <AudiencePanel audienceId={data.audienceId} />}

      <DetailsSponsoredBrands data={data} />

      <InfoView data={data} />

      <StatusBar
        status={data?.status}
        message={
          data?.status_history?.length > 0 ? data?.status_history[0]?.note : ''
        }
      />

      <hr />

      <DestinationUrls data={data} loading={loading} />

      <StatusHistory data={data?.status_history} />
    </>
  )
}
export default ViewSponsoredBrandCampaign
