import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { Form } from '@unform/web'

import Panel from '~/components/Panel'
import { Label, Select } from '~/components/unform'
import { isPublisher } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { patch } from '~/modules/retailMedia/store/industry/campaign/actions'
import { useAppSelector } from '~/store/hooks'

// import { Container } from './styles';

const DebugSettings: React.FC = () => {
  const query = useQueryParams()
  const debugMode = query.get('debug') || null

  const {
    campaign: {
      item: { data }
    }
  } = useAppSelector(state => state.retailMedia)

  const dispatch = useDispatch()

  const handleChange = useCallback(
    formData => {
      const body = {
        settings: { budget_type: formData === 'null' ? null : formData }
      }

      dispatch(
        patch({
          body,
          id: data?.id,
          successFeedbackMsg:
            'As configurações da campanha foram atualizadas com sucesso.'
        })
      )
    },
    [data, dispatch]
  )

  return debugMode === 'settings' && !isPublisher() && !!data ? (
    <Panel title="Configurações avançadas da campanha">
      <Form initialData={data}>
        <Label text="Budget type" htmlFor="settings.budget_type" />
        <Select
          name="settings.budget_type"
          onChange={handleChange}
          defaultValue={data?.settings?.budget_type || 'null'}
          options={[
            {
              id: 'null',
              value: 'null',
              text: 'Desativado'
            },
            {
              id: 'evenly',
              value: 'evenly',
              text: 'Evenly'
            }
          ]}
        />
      </Form>
    </Panel>
  ) : (
    <></>
  )
}

export default DebugSettings
