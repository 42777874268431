import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import { useMutation } from '@tanstack/react-query'
import { FormHandles, Scope } from '@unform/core'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Form from '~/components/unform/Form'
import { useErrorApiAlert } from '~/hooks/useErrorApiAlert'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getCampaign } from '~/modules/retailMedia/store/campaign/actions'
import { useAppSelector } from '~/store/hooks'

import { addBannerPost } from '../../services/industry/ad'
import { BannerAddFormContent } from '../CampaignForm/elements/Banner/BannerAddFormContent'

import * as S from './styles'

export const Banner: React.FC<AddAdComponentProps<FormHandles>> = ({
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleClose
}) => {
  const { t, tToasts } = useAPPTranslation()
  const dispatch = useDispatch()

  // copy of origin form
  const [bannersToTable, setBannersToTable] = useState([])

  // Handle error
  const [AlertErrorAdd, setErrorAdd] = useErrorApiAlert({
    title: t('rm:actions.campaign.add.banners.error.message')
  })

  const {
    campaign: {
      item: { data }
    }
  } = useAppSelector(state => state.retailMedia)

  // Atualiza os dados em caso de sucesso
  const handleSuccess = useCallback(() => {
    dispatch(
      getCampaign({
        ...queryParams,
        account_info: true,
        requestInBackground: true
      })
    )
    handleClose()
  }, [dispatch, queryParams, handleClose])

  const { mutate, isPending } = useMutation({
    mutationFn: addBannerPost,
    onSuccess: () => {
      handleSuccess()

      tToasts({
        prefix: 'rm:actions.campaign.add.banners.success',
        toastFunction: toastr.success
      })
    },
    onError: error => {
      console.error(error)

      setErrorAdd(error)

      tToasts({
        prefix: 'rm:actions.campaign.add.banners.error',
        toastFunction: toastr.error
      })
    }
  })

  // Request para adicionar novos items no ProductsPanel

  const handleSubmitEdit = useCallback(() => {
    const body = {
      campaign_id: data.id,
      publisher_id: data.publisher.id,
      assets: bannersToTable.map(({ ad_size, media_url, alt_text }) => ({
        ad_size,
        media_url,
        alt_text
      }))
    }

    mutate({ body })
  }, [bannersToTable, data.id, data.publisher.id, mutate])

  return (
    <Form ref={formRef} onSubmit={handleSubmitEdit} className="px-3">
      <Scope path="banner">
        <BannerAddFormContent
          formRef={formRef}
          bannersToTable={bannersToTable}
          setBannersToTable={setBannersToTable}
          selectedSegmentation={data?.targetingType}
          initialData={{ publisher_id: data?.publisher.id }}
        />
      </Scope>

      <footer className="mt-3">
        <Loading status={isPending}>{t('rm:savingChanges')}</Loading>

        <AlertErrorAdd />

        {showEdit && !isPending && (
          <S.WrapperButtons className="form-default__buttons form-default__buttons-pb0">
            <Button
              template="transparentDanger"
              text={t('rm:button.Cancel')}
              onClick={handleClose}
              disabled={isPending}
            />

            <Button
              template="success"
              text={t('rm:campaign.create.ProductsPanel.add.button')}
              textLoading={t('rm:saving')}
              onClick={handleClickOnSave}
              disabled={!bannersToTable.length || isPending}
              loading={isPending}
            />
          </S.WrapperButtons>
        )}
      </footer>
    </Form>
  )
}
