import { useCallback, useMemo } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Form from '~/components/unform/Form'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import ProductsPanel from '~/modules/retailMedia/components/ProductsPanel'
import { useAppSelector } from '~/store/hooks'

import {
  addRelatedProductService,
  listProductResult,
  listProductResultKey
} from '../../services/campaign/product'

import * as S from './styles'

export const AddRelatedProduct: React.FC<AddRelatedProductProps> = ({
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleClose,
  publisherId,
  onSuccess
}) => {
  const { t, tToasts } = useAPPTranslation()

  const queryClient = useQueryClient()

  const {
    campaign: {
      products: { selectedProducts: newProducts }
    }
  } = useAppSelector(state => state.retailMedia)

  const { data } = useQuery({
    queryKey: [listProductResultKey, queryParams.campaign_id, queryParams],
    queryFn: async () => {
      const { campaign_id } = queryParams

      const response = await listProductResult(campaign_id, {
        count: false
      })

      const currentProducts: CurrentRelatedProduct[] = response.data.map(
        item => ({
          ...item,
          item_disabled: true
        })
      )

      return currentProducts
    },
    enabled: !!queryParams.campaign_id
  })

  const { mutate, isPending: loadingSave } = useMutation({
    mutationFn: addRelatedProductService,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [listProductResultKey]
      })

      tToasts({
        prefix: 'rm:ad.relatedProduct.success',
        toastFunction: toastr.success
      })

      onSuccess?.()
      handleClose()
    },
    onError: () => {
      tToasts({
        prefix: 'rm:ad.relatedProduct.error',
        toastFunction: toastr.error
      })
    }
  })

  const mapProductsID = useMemo(
    () => newProducts.map(({ id, product_sku }) => ({ id, product_sku })),
    [newProducts]
  )

  const handleSubmitEdit = useCallback(() => {
    const productIds = mapProductsID.map(p => p.id).filter(Boolean)
    const productSkus = mapProductsID.map(p => p.product_sku).filter(Boolean)

    const payload = {
      id: queryParams.campaign_id,
      body:
        productIds.length > 0
          ? { product_ids: productIds }
          : { product_skus: productSkus }
    }

    mutate(payload)
  }, [mapProductsID, queryParams.campaign_id, mutate])

  const Buttons = () => {
    return (
      <>
        <Loading status={loadingSave}>{t('rm:savingChanges')}</Loading>

        {showEdit && !loadingSave && (
          <S.WrapperButtons className="form-default__buttons form-default__buttons-pb0">
            <Button
              template="primaryOutlined"
              text={t('rm:button.Cancel')}
              onClick={handleClose}
              disabled={loadingSave}
            />

            <Button
              template="success"
              text={t('rm:button.Save')}
              textLoading={t('rm:saving')}
              onClick={handleClickOnSave}
              disabled={!mapProductsID.length || loadingSave}
              loading={loadingSave}
            />
          </S.WrapperButtons>
        )}
      </>
    )
  }

  return (
    <div className="AddAdForm">
      <Form ref={formRef} onSubmit={handleSubmitEdit}>
        {data && (
          <ProductsPanel
            formRef={formRef}
            publisherId={publisherId}
            description={t('rm:campaign.create.ProductsPanel.description')}
            isCollapsible={false}
            isEdit={false}
            Buttons={<Buttons />}
            currentProducts={data}
          />
        )}
      </Form>
    </div>
  )
}
