import { IconType } from 'react-icons'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { BiCheck, BiCheckDouble } from 'react-icons/bi'
import { FiCheck, FiWatch, FiXCircle, FiRefreshCw } from 'react-icons/fi'

import { isPublisher } from '~/helpers'
import i18n from '~/i18n'

const { t } = i18n

interface ValuesObject {
  label: string
  icon: IconType
  iconHistory: IconType
  text: string
  color: 'success' | 'success_alert' | 'warning' | 'danger' | 'info'
  type?: string
  canEditDate: boolean
}

type Status = {
  [key in CampaignStatus]: ValuesObject
}

export const statusType: Status = {
  approved: {
    label: t('rm:approved'),
    icon: FiCheck,
    iconHistory: FiCheck,
    text: t('rm:status.approved.description'),
    color: 'success',
    type: 'approved',
    canEditDate: true
  },
  running: {
    label: t('rm:running'),
    icon: BiCheckDouble,
    iconHistory: BiCheckDouble,
    text: t('rm:status.running.description'),
    color: 'success',
    type: 'running',
    canEditDate: true
  },
  partial_running: {
    label: t('rm:partialRunning'),
    icon: BiCheck,
    iconHistory: BiCheck,
    text: t('rm:status.partialRunning.description'),
    color: 'success_alert',
    type: 'partial_running',
    canEditDate: true
  },
  scheduled: {
    label: t('rm:scheduled'),
    icon: FiCheck,
    iconHistory: FiCheck,
    text: t('rm:status.scheduled.description'),
    color: 'success',
    type: 'scheduled',
    canEditDate: true
  },
  finished: {
    label: t('rm:finished'),
    icon: FiCheck,
    iconHistory: FiCheck,
    text: t('rm:status.finished.description'),
    color: 'info',
    type: 'finished',
    canEditDate: false
  },
  canceled: {
    label: t('rm:canceled'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiXCircle,
    text: t('rm:status.canceled.description'),
    color: 'danger',
    type: 'canceled',
    canEditDate: false
  },
  pending_review: {
    label: t('rm:awaitingApproval'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiWatch,
    text: t(
      isPublisher()
        ? 'rm:status.awaitingApproval.description.fullPublisher'
        : 'rm:status.awaitingApproval.description'
    ),
    color: 'warning',
    type: 'pending_review',
    canEditDate: true
  },
  rejected: {
    label: t('rm:rejected'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiXCircle,
    text: t('rm:status.rejected.description'),
    color: 'danger',
    type: 'rejected',
    canEditDate: false
  },
  paused: {
    label: t('rm:status.paused.title'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiXCircle,
    text: t('rm:status.paused.description'),
    color: 'warning',
    type: 'paused',
    canEditDate: true
  },
  out_of_budget: {
    label: t('rm:outOfBudget'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiXCircle,
    text: t('rm:status.outOfBudget.description'),
    color: 'warning',
    type: 'out_of_budget',
    canEditDate: true
  },
  awaiting_approval: {
    label: t('rm:awaitingApproval'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiWatch,
    text: t('rm:status.awaitingApproval.description'),
    color: 'warning',
    type: 'pending_review',
    canEditDate: true
  },
  awaiting_publication: {
    label: t('rm:awaitingPublication'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiWatch,
    text: t('rm:status.awaitingApproval.description'),
    color: 'warning',
    type: 'awaiting_publication',
    canEditDate: true
  },
  pending: {
    label: t('rm:pending'),
    icon: FiWatch,
    iconHistory: FiWatch,
    text: t('rm:status.pending.description'),
    color: 'warning',
    type: 'pending',
    canEditDate: true
  },
  updating: {
    label: t('rm:updating'),
    icon: AiOutlineInfoCircle,
    iconHistory: FiRefreshCw,
    text: t('rm:status.updating.description'),
    color: 'success_alert',
    type: 'updating',
    canEditDate: true
  }
}
