import { FormHandles } from '@unform/core'

import { Banner } from './Banner'
import { Product } from './Product'

export const AddAd: React.FC<AddAdProps<FormHandles>> = ({
  adType,
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleClose
}) => {
  const allComponents = {
    product: Product,
    banner: Banner
  }

  const Component = allComponents?.[adType]

  return (
    <div className="AddAdForm">
      <Component
        showEdit={showEdit}
        formRef={formRef}
        queryParams={queryParams}
        handleClickOnSave={handleClickOnSave}
        handleClose={handleClose}
      />
    </div>
  )
}
