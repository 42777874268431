import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { t } from 'i18next'

import ToggleInput from '~/components/Toggle'
import Tooltip from '~/components/Tooltip'
import { patch } from '~/modules/retailMedia/services/industry/ad'

import { useAdsTable } from '../_tables/AdsTable/hooks/useAdsTable'

const ToggleAdverts = ({ data, showLabel = false }: ToggleAdvertsProps) => {
  const [active, setActive] = useState<boolean>(data?.active)

  const { handleMutate } = useAdsTable()

  const label = useMemo(
    () => (active ? t('rm:ad.active') : t('rm:ad.deactivated')),
    [active]
  )

  const canEdit = useMemo(() => !!data?.canEditCampaign, [data])

  const isChecked = useMemo(() => data?.active, [data])

  const handleSuccess = useCallback(
    (value: boolean) => {
      handleMutate({ value, id: data.id })
    },
    [data?.id, handleMutate]
  )

  const handleChangeToggle = useCallback(
    async ({ value, onSuccess, data }: HandleChangeToggleProps) => {
      try {
        const id = data.id

        await patch({ id, body: { active: value } })

        if (value) {
          toastr.success(`${t('rm:ad.active')}!`, t('rm:ad.active.description'))
        } else {
          toastr.success(
            `${t('rm:ad.deactivated')}!`,
            t('rm:ad.deactivated.description')
          )
        }

        onSuccess?.(value)
      } catch (error) {
        console.error(error)
        setActive(false)
        toastr.error('Erro', t('common:messages.errorOccurred.message'))
        onSuccess?.(!value)
      }
    },
    []
  )

  type HandleChangeProps = {
    e: ChangeEvent<HTMLInputElement>
  }

  const handleChange = useCallback(
    ({ e }: HandleChangeProps) => {
      const value = e.target.checked

      setActive(value)

      handleChangeToggle({
        value,
        data,
        onSuccess: handleSuccess
      })
    },
    [data, handleChangeToggle, handleSuccess]
  )

  return (
    <Tooltip
      key={`toggle_adverts_${data?.id}`}
      icon={
        <ToggleInput
          key={`adverts_${data?.id}`}
          name={`adverts_${data?.id}`}
          isChecked={isChecked}
          label={showLabel ? label : null}
          onChange={handleChange}
          data-testid="toggleAdverts"
          disabled={!canEdit}
        />
      }
      text={label}
      positionProp="right"
    />
  )
}

export default ToggleAdverts
