import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { FormHandles } from '@unform/core'

import { Button } from '~/components/Buttons'
import Loading from '~/components/Loading'
import Form from '~/components/unform/Form'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { getCampaign } from '~/modules/retailMedia/store/campaign/actions'
import { editCampaign } from '~/modules/retailMedia/store/industry/campaign/actions'
import { useAppSelector } from '~/store/hooks'

import ProductsPanel from '../ProductsPanel'

import * as S from './styles'

export const Product: React.FC<AddAdComponentProps<FormHandles>> = ({
  showEdit,
  formRef,
  queryParams,
  handleClickOnSave,
  handleClose
}) => {
  const { t } = useAPPTranslation()
  const dispatch = useDispatch()

  const {
    campaign: {
      item: { data },
      products: { selectedProducts }
    },
    industry: {
      campaign: {
        patch_product: { loading: loadingSave }
      }
    }
  } = useAppSelector(state => state.retailMedia)

  // Atualiza os dados em caso de sucesso
  const handleSuccess = useCallback(() => {
    dispatch(getCampaign({ ...queryParams, account_info: true }))
    handleClose()
  }, [dispatch, handleClose, queryParams])

  // filtra somente item novo
  const newAds = useMemo(
    () =>
      selectedProducts
        .filter(item => !item.item_disabled)
        .map(item => ({
          settings: {
            ...item.settings,
            sku: `${item.product_sku}`
          }
        })),
    [selectedProducts]
  )

  // Request para adicionar novos items no ProductsPanel
  const handleSubmitEdit = useCallback(() => {
    const payload = {
      data: {
        id: queryParams.campaign_id,
        body: {
          ads: newAds
        }
      }
    }

    try {
      dispatch(editCampaign({ ...payload, onSuccess: handleSuccess }))
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, handleSuccess, newAds, queryParams.campaign_id])

  const Buttons = () => {
    return (
      <>
        <Loading status={loadingSave}>{t('rm:savingChanges')}</Loading>

        {showEdit && !loadingSave && (
          <S.WrapperButtons className="form-default__buttons form-default__buttons-pb0">
            <Button
              template="primaryOutlined"
              text={t('rm:button.Cancel')}
              onClick={handleClose}
              disabled={loadingSave}
            />

            <Button
              template="success"
              text={t('rm:campaign.create.ProductsPanel.add.button')}
              textLoading={t('rm:saving')}
              onClick={handleClickOnSave}
              disabled={!newAds.length || loadingSave}
              loading={loadingSave}
            />
          </S.WrapperButtons>
        )}
      </>
    )
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmitEdit}>
      <ProductsPanel
        formRef={formRef}
        publisherId={data?.publisher?.id}
        description={t('rm:campaign.create.ProductsPanel.description')}
        isCollapsible={false}
        isEdit={showEdit}
        Buttons={<Buttons />}
      />
    </Form>
  )
}
