import { useCallback, useMemo, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { useParams } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { t } from 'i18next'

import ToggleInput from '~/components/Toggle'
import Tooltip from '~/components/Tooltip'

import { toggleProductStatusService } from '../../services/campaign/product'

const ToggleActiveCampaignProduct = ({ data, showLabel = false }) => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [active, setActive] = useState<boolean>(data?.active)

  const label = useMemo(
    () => (active ? t('rm:ad.activeProduct') : t('rm:ad.deactivatedProduct')),
    [active]
  )

  const isChecked = useMemo(() => active, [active])

  const { mutate, isPending } = useMutation({
    mutationFn: async (value: boolean) => {
      const payload = {
        productId: data.id,
        body: {
          active: value,
          campaign_id: id
        }
      }
      return toggleProductStatusService(payload)
    },
    onSuccess: (_, value) => {
      queryClient.invalidateQueries({
        queryKey: ['list-related-products']
      })

      toastr.success(
        value ? t('rm:ad.activeProduct') : t('rm:ad.deactivatedProduct'),
        value
          ? t('rm:ad.activeProduct.description')
          : t('rm:ad.deactivatedProduct.description')
      )

      setActive(value)
    },
    onError: error => {
      console.error(error)
      toastr.error('Erro', t('common:messages.errorOccurred.message'))
      setActive(prev => !prev)
    }
  })

  const handleChange = useCallback(
    (e: { e: { target: { checked: boolean } } }) => {
      const value = e.e.target.checked
      setActive(value)
      mutate(value, {
        onError: () => {
          setActive(prev => !prev)
        }
      })
    },
    [mutate]
  )

  return (
    <Tooltip
      icon={
        <ToggleInput
          name={`product_${data?.product_sku}`}
          isChecked={isChecked}
          label={showLabel ? label : null}
          onChange={handleChange}
          data-testid="toggleAdverts"
          disabled={isPending}
          size="small"
        />
      }
      text={label}
      positionProp="right"
    />
  )
}

export default ToggleActiveCampaignProduct
