import React, { useCallback, useState, useMemo } from 'react'
import { FiDownload } from 'react-icons/fi'
import { toastr } from 'react-redux-toastr'

import axios from 'axios'
import { t } from 'i18next'
import FileDownload from 'js-file-download'

import { Button } from '~/components/Buttons'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'

import * as S from './styles'

type ExportType =
  | 'advertisers'
  | 'publishers'
  | 'campaigns'
  | 'ads'
  | 'adsList'
  | 'subpublishers'

const exportSettingsTypes = {
  advertisers: {
    path: '/report/v2/advertisers',
    filename: t('rm:report.advertisers')
  },
  publishers: {
    path: '/report/v2/publishers',
    filename: t('rm:report.publishers')
  },
  campaigns: {
    path: '/campaign/v2',
    filename: t('rm:report.campaigns')
  },
  ads: {
    path: '/campaign/v2',
    filename: t('rm:report.ads')
  },
  adsList: {
    path: '/ad/results/v2',
    filename: t('rm:report.ads')
  },
  subpublishers: {
    path: '/report/network/publishers',
    filename: t('rm:report.publishers')
  }
}

type ExportButtonProps = {
  queryParams: any
  exportType: ExportType
  routeParams?: any
  customStyle?: React.CSSProperties
}

const ExportButton = ({
  queryParams,
  routeParams,
  exportType,
  customStyle
}: ExportButtonProps) => {
  const [loadingDownload, setLoadingDownload] = useState(false)

  const exportSettings = exportSettingsTypes[exportType as ExportType]

  if (routeParams) exportSettings.path += `/${routeParams}`

  const urlExportXlsx = useMemo(
    () =>
      `${reatailmediaApi}${exportSettings.path}?download=true&${queryParams}`,
    [exportSettings, queryParams]
  )

  const handleDownloadXLSX = useCallback(async () => {
    try {
      setLoadingDownload(true)

      const response = await axios.get(urlExportXlsx, { responseType: 'blob' })

      const contentDisposition = response.headers['content-disposition']
      const fileNameMatch = /filename="?([^"]+)"?/.exec(contentDisposition)
      const fileName =
        fileNameMatch && fileNameMatch?.[1]
          ? fileNameMatch[1]
          : `${exportSettings.filename}.xlsx`

      FileDownload(response.data, fileName)
    } catch (error) {
      toastr.error(t('rm:toast.error'), t('rm:report.error.exportingData'))
    } finally {
      setLoadingDownload(false)
    }
  }, [urlExportXlsx, exportSettings])

  return (
    <S.ExportButton style={{ ...customStyle }}>
      <Button
        onClick={handleDownloadXLSX}
        loading={loadingDownload}
        disabled={loadingDownload}
        template="light"
        iconLeft={<FiDownload />}
        text={t('rm:Export')}
        textLoading={t('rm:Exporting')}
        size="small"
        customWidth="auto"
      />
    </S.ExportButton>
  )
}

export default ExportButton
