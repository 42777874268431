import AlertInfo from '~/components/AlertInfo'
import Loading from '~/components/Loading'
import { getAccountInfo } from '~/helpers/account-info'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import AdListFilters from '~/modules/retailMedia/components/_tables/AdListFilters'
import AdsTable from '~/modules/retailMedia/components/_tables/AdsTable'
import CampaignPlacementsTable from '~/modules/retailMedia/components/_tables/CampaignPlacementsTable'
import AudiencePanel from '~/modules/retailMedia/components/AudiencePanel'
import Budget from '~/modules/retailMedia/components/Budget'
import DailyHistoryTable from '~/modules/retailMedia/components/DailyHistoryTable'
import { InfoView } from '~/modules/retailMedia/components/InfoView'
import { StatusBar } from '~/modules/retailMedia/components/StatusBar'
import StatusHistory from '~/modules/retailMedia/components/StatusHistory'

import MetricsCampaignView from '../Metrics'

const ViewProductCampaign: React.FC<ViewCampaignProps> = ({
  data,
  loading
}) => {
  const { t } = useAPPTranslation()

  const hasPlacements = !!data.settings?.has_placements
  const hasAudience = !!data.audienceId
  const isSelller = !!getAccountInfo()?.sellerId

  return (
    <>
      <AdListFilters adType={data?.adType} />

      <MetricsCampaignView adType={data.adType} />

      <Loading status={loading}>Carregando dados das campanhas...</Loading>

      {isSelller && (
        <section className="mb-2">
          <AlertInfo template="info" text={t('rm:alert.seller.buyBox')} />
        </section>
      )}

      <AdsTable campaignAdType={data.adType} />

      <hr />

      <DailyHistoryTable
        campaignType={data.adType}
        campaignStartDate={data.raw.start_at}
      />

      <hr />

      <Budget data={data} loading={loading} />

      {hasAudience && <AudiencePanel audienceId={data.audienceId} />}

      {hasPlacements && (
        <>
          <hr />
          <CampaignPlacementsTable campaignId={data.id} />
        </>
      )}

      <InfoView data={data} />

      <StatusBar
        status={data?.status}
        message={
          data?.status_history?.length > 0 ? data?.status_history[0]?.note : ''
        }
      />

      <StatusHistory data={data?.status_history} />
    </>
  )
}

export default ViewProductCampaign
