import React, { useState, useMemo, useEffect, useCallback, Ref } from 'react'
import { FiList, FiSearch, FiUpload } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '~/components/Buttons'
import EmptyPage from '~/components/empty-page'
import { FilterSearch } from '~/components/FilterSearch'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { FormGroup, Label, Textarea } from '~/components/unform'
import Product from '~/modules/promotional/components/Product'
import {
  deleteAllProductsOnPromotion,
  addProductsBulk
} from '~/modules/promotional/store/promotion/actions'
import * as types from '~/modules/promotional/store/promotion/constants'
import { searchOffers } from '~/store/modules/products/actions'

import * as S from './styles'

type TabKeys = 'multipleProductsPerId' | 'searchProduct'

interface Props {
  formRef?: Ref<HTMLFormElement>
  isEdit?: boolean
  fakeInitialData?: any
}

const ProductsPanel = ({ formRef, isEdit = false, fakeInitialData }: Props) => {
  const [bulkProducts, setBulkProducts] = useState(false)
  const [activeTab, setActiveTab] = useState<TabKeys>('multipleProductsPerId')

  const handleTabs = useCallback((tabKey: TabKeys) => setActiveTab(tabKey), [])

  const HeaderSideContent = () => (
    <aside>
      <Button
        size="xsmall"
        template="transparentPrimary"
        text="Importar .XLS"
        iconLeft={<FiUpload />}
      />
    </aside>
  )

  // Busca de produto
  const dispatch = useDispatch()

  const { listProductsLoading, listProducts: searchProducts } = useSelector(
    state => state.products
  )

  const { query } = useSelector(state => state.filters)

  const { selectedProducts, loadingAddBulk } = useSelector(
    state => state.promotional
  )
  const selectedSKUS = selectedProducts?.map(item => item.external_id)

  const filteredQuery = useMemo(
    () => query?.[`${types.PRODUCT_REDUX_QUERY}`] || null,
    [query]
  )

  const queryStringSearchProduct = useMemo(
    () => ({
      quantity: 50,
      query: filteredQuery
    }),
    [filteredQuery]
  )

  useEffect(() => {
    if (activeTab === 'searchProduct' && filteredQuery) {
      dispatch(searchOffers(queryStringSearchProduct))
    }
  }, [activeTab, dispatch, filteredQuery, queryStringSearchProduct])

  const handleDeleteAllProducts = useCallback(
    () => dispatch(deleteAllProductsOnPromotion()),
    [dispatch]
  )

  const handleChangeProductsBulk = useCallback(e => {
    const data = e.target.value

    setBulkProducts(data)
  }, [])

  const handleAddProductsBulk = useCallback(() => {
    dispatch(addProductsBulk({ productsIds: bulkProducts }))

    formRef.current.setFieldValue('bulkProducts', '')
  }, [bulkProducts, dispatch, formRef])

  return (
    <Panel title="Produtos" isCollapsible initialCollapsibleState={!isEdit}>
      <S.Header>
        <Label isRequired>
          Selecione os produtos que deseja incluir na promoção.
        </Label>
      </S.Header>

      {/* Lista de pesquisa */}
      <S.ProductsLists>
        <S.ProductsList>
          <S.ProductsListHeader>
            <S.Nav>
              <Button
                template={
                  activeTab === 'multipleProductsPerId' ? 'primary' : 'light'
                }
                onClick={() => handleTabs('multipleProductsPerId')}
                text="Adicionar por ID"
                size="small"
                iconLeft={<FiList />}
              />

              <Button
                template={activeTab === 'searchProduct' ? 'primary' : 'light'}
                onClick={() => handleTabs('searchProduct')}
                text="Buscar produtos"
                size="small"
                iconLeft={<FiSearch />}
                data-testid="searchProductButton"
              />
            </S.Nav>

            {activeTab === 'searchProduct' && (
              <FilterSearch
                placeholder="Buscar o produto por nome, ID ou SKU"
                keyState={`${types.PRODUCT_REDUX_QUERY}`}
                showSubmitButton
                searchOnEnter={false}
              />
            )}

            <Loading status={listProductsLoading}>Buscando produtos...</Loading>

            {/* Esta seção permitirá o usuário adicionar vários produtos de uma vez */}
            {activeTab === 'multipleProductsPerId' && (
              <S.BulkSection>
                <Loading status={loadingAddBulk}>
                  Adicionando produtos...
                </Loading>

                <FormGroup>
                  <Label htmlFor="bulkProducts" isRequired>
                    Insira os códigos separados por linha
                  </Label>
                  <Textarea
                    name="bulkProducts"
                    type="text"
                    // eslint-disable-next-line max-len
                    placeholder="Por exemplo:&#10;12345&#10;32432423&#10;1243432&#10;3432432&#10;123457&#10;32432423&#10;12434432&#10;34324352"
                    onChange={handleChangeProductsBulk}
                    data-testid="textAreaTest"
                  />
                </FormGroup>

                <FormGroup className="actionButtons">
                  <Button
                    text="Adicionar"
                    onClick={handleAddProductsBulk}
                    disabled={!bulkProducts}
                    data-testid="buttonAdd"
                  />
                </FormGroup>
              </S.BulkSection>
            )}
          </S.ProductsListHeader>

          {activeTab === 'searchProduct' && filteredQuery && (
            <div>
              {searchProducts?.map(item => (
                <Product
                  hasAction
                  key={item.id}
                  data={item}
                  selectedSKUS={selectedSKUS}
                />
              ))}
            </div>
          )}
        </S.ProductsList>

        {/* Lista de selecionadas */}
        <S.ProductsList>
          <S.ProductsListHeader>
            <S.Heading>
              <S.Title>Selecionados</S.Title>

              {!!selectedProducts?.length && (
                <Button
                  template="transparent"
                  text="Remover todos"
                  onClick={handleDeleteAllProducts}
                  size="xsmall"
                />
              )}
            </S.Heading>
          </S.ProductsListHeader>

          <div>
            {selectedProducts?.map(item => (
              <Product hasAction key={item.id} data={item} isSelected />
            ))}

            {fakeInitialData?.map(item => (
              <Product hasAction key={item.id} data={item} isSelected />
            ))}
          </div>

          {selectedProducts?.length === 0 && !fakeInitialData && (
            <div className="empty">
              <EmptyPage
                title="Nenhum produto selecionado"
                subtitle="Use o painel ao lado para adicionar produtos à promoção."
                svgPath="photos/orders-empty"
              />
            </div>
          )}
        </S.ProductsList>
      </S.ProductsLists>
    </Panel>
  )
}

export default ProductsPanel
