import { useCallback, useMemo, useState } from 'react'
import { toastr } from 'react-redux-toastr'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@unform/web'

import { Button } from '~/components/Buttons'
import { userRolesOptions } from '~/components/FilterUserRoles'
import { FormGroup, Label } from '~/components/unform'
import ReactSelect from '~/components/unform/ReactSelect'
import { getAccountType } from '~/helpers'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { userChangeRole } from '~/services/user-service'

import { listUserByAccountServiceKey } from '../../services/user-service'

type FormData = {
  role: UserRoles
}

type OnChangeRole = {
  label: string
  value: UserRoles
} | null

const FormEditPermission = ({
  data,
  onClose
}: {
  data: UserResponse
  onClose: () => void
}) => {
  const [formData, setFormData] = useState<FormData>({
    role: data.role
  } as FormData)

  const { t } = useAPPTranslation()

  const handleChangeRole = (data: OnChangeRole) => {
    setFormData({ role: data?.value || null })
    return
  }

  /**
   * Handle patch user role
   */

  const queryClient = useQueryClient()

  const { isPending: isPendingSave, mutateAsync } = useMutation({
    mutationFn: userChangeRole,
    onSuccess: () => {
      toastr.success(t('c:words.success'), t('users:userEditedSuccess'))

      queryClient.invalidateQueries({
        queryKey: [listUserByAccountServiceKey]
      })

      onClose()
    },
    onError: () => {
      toastr.error(
        t('common:messages.errorOccurred.title'),
        t('c:messages.errorOccurred.message')
      )
    }
  })

  const handleSubmit = useCallback(() => {
    const newData = { ...formData, user_id: data?.id }

    mutateAsync(newData)
  }, [formData, data?.id, mutateAsync])

  // handle options
  const options = useMemo(() => {
    const list = userRolesOptions.slice(1)

    if (getAccountType() === 'grocery') {
      return list
    }

    // only admin, user and read_only
    return list.filter(item =>
      ['admin', 'user', 'read_only'].includes(item.value)
    )
  }, [])

  return (
    <section className="px-3">
      <h4>
        {data.name} ({data.email})
      </h4>
      <Form onSubmit={handleSubmit} initialData={{ role: data.role }}>
        <FormGroup className="span2  span-start">
          <Label isRequired text={t('users:permission')} />
          <ReactSelect
            options={options}
            placeholder={t('users:selectPermission')}
            name="role"
            isClearable={false}
            onChange={handleChangeRole}
            defaultValue={options.find(item => item.value === data.role)}
          />
        </FormGroup>

        <footer className="d-flex mt-2 gap-2">
          <Button
            template="light"
            size="small"
            text={t('common:actions.cancel')}
            customWidth="100px"
            onClick={onClose}
            disabled={isPendingSave}
          />

          <Button
            size="small"
            text={t('common:actions.save')}
            loading={isPendingSave}
            disabled={isPendingSave}
            customWidth="130px"
            type="submit"
          />
        </footer>
      </Form>
    </section>
  )
}

export default FormEditPermission
