import { useCallback, useEffect } from 'react'

import { t } from 'i18next'

import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Table from '~/components/Table'
import { history, isReadOnly } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import { useSidePanel } from '~/hooks/useSidePanel'
import AdvertisersFilters from '~/modules/retailMedia/components/_tables/AdvertiserFilters'

import { useAdvertiserPublisherLinks } from '../../hooks/useAdvertiserPublisherLinks'
import EditAdvertiserLink from '../EditAdvertiserLink'
import EditAdvertiserTags from '../EditAdvertiserTags'
import { advertisersLinkTableSchema } from './schema'

// import { Container } from './styles';

const AdvertiserLinksTable: React.FC = () => {
  const params = useQueryParams()
  const page = Number(params.get('page')) || 1
  const quantity = Number(params.get('quantity')) || 50
  const advertiserId = params.get('rmid')
  const editTagAdvertiserId = params.get('edit_tags_aid')

  /**
   * Get advertisers
   */

  const {
    data: { data, total, currentPage },
    isPending
  } = useAdvertiserPublisherLinks()

  const handlePagination = useCallback(
    (value: number) => {
      params.set('page', String(value))
      history.push({ search: params.toString() })
    },
    [params]
  )

  const handleItemsPerPageChange = useCallback(
    (value: { value: number }) => {
      params.set('quantity', String(value.value))
      params.set('page', '1')
      history.push({ search: params.toString() })
    },
    [params]
  )

  // Patch
  const { addSidePanel, removeSidePanel } = useSidePanel()

  const handleClosePanel = useCallback(() => {
    const newParams = new URLSearchParams(params.toString())
    newParams.delete('rmid')
    history.push({ search: newParams.toString() })
  }, [params])

  useEffect(() => {
    if (advertiserId && !isReadOnly()) {
      addSidePanel({
        id: `panel-advertiser`,
        title: t('rm:config.advertisers.panel.title'),
        type: 'content',
        content: <EditAdvertiserLink key={advertiserId} />,
        callbackRemove: handleClosePanel
      })
    }

    if (!advertiserId) {
      removeSidePanel({
        id: `panel-advertiser`
      })
    }
  }, [
    addSidePanel,
    advertiserId,
    handleClosePanel,
    page,
    params,
    quantity,
    removeSidePanel
  ])

  // Handle advertiser tags
  const {
    addSidePanel: addSidePanelAdvertiserTag,
    removeSidePanel: removeSidePanelAdvertiserTag
  } = useSidePanel()

  const handleClosePanelAdvertiserTag = useCallback(() => {
    const newParams = new URLSearchParams(params.toString())
    newParams.delete('edit_tags_aid')
    history.push({ search: newParams.toString() })
  }, [params])

  useEffect(() => {
    if (editTagAdvertiserId && !isReadOnly()) {
      addSidePanelAdvertiserTag({
        id: `panel-advertiser-tag`,
        title: t('rm:config.advertisers.tags.panel.title'),
        type: 'content',
        content: <EditAdvertiserTags />,
        callbackRemove: handleClosePanelAdvertiserTag
      })
    }

    if (!editTagAdvertiserId) {
      removeSidePanelAdvertiserTag({
        id: `panel-advertiser-tag`
      })
    }
  }, [
    addSidePanelAdvertiserTag,
    editTagAdvertiserId,
    handleClosePanelAdvertiserTag,
    removeSidePanelAdvertiserTag
  ])

  return (
    <section>
      <AdvertisersFilters />

      <ListPagination
        total={total}
        label={t(
          `rm:advertisers.pagination.label.${total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={currentPage}
        itemsPerPage={quantity}
        menuPlacement="bottom"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />

      <Table
        smallerRowPadding
        schema={advertisersLinkTableSchema()}
        data={data?.advertisers}
        total={total}
        sideBorder
      />

      <Loading status={isPending} />

      <ListPagination
        total={total}
        label={t(
          `rm:advertisers.pagination.label.${total > 1 ? 'plural' : 'singular'}`
        )}
        currentPage={page}
        itemsPerPage={quantity}
        menuPlacement="top"
        onClickPagination={handlePagination}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </section>
  )
}

export default AdvertiserLinksTable
