import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from '@tanstack/react-query'

import BadgeIsValid from '~/components/BadgeIsValid'
import Panel from '~/components/Panel'
import SelectSearchable from '~/components/SelectSearchable'
import { FormGroup } from '~/components/unform'
import { getAccountId, history } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

import {
  getPublishersService,
  listPublishersServiceKey
} from '../../services/advertiser-publisher'

const formatURL = (id: BrandedType<string, 'PublisherId'>) => {
  const url = new URL(window.location.href)
  url.searchParams.set('rmid', String(id))
  return url.toString()
}

const PublishersFieldset = ({
  onChange,
  description: customDescription,
  isCollapsible = false
}: PublishersFieldsetProps) => {
  const query = useQueryParams()

  const { t } = useTranslation()
  const description =
    customDescription || t('rm:audience.publisherAudiences.description')

  /**
   * Lógica de apoio
   */
  const rmid = useMemo(() => query.get('rmid') || null, [query])

  /**
   * Lógica para a funcionalidade principal
   */
  const { data: publishers, isPending: loading } = useQuery({
    queryKey: [listPublishersServiceKey, getAccountId],
    queryFn: async () => {
      const { data } = await getPublishersService({
        quantity: 5000
      })

      return data.publishers
    }
  })

  /**
   * Tratando casos com apenas um publisher
   */
  const hasOnlyOnePublisher = publishers?.length === 1

  useEffect(() => {
    if (!rmid && hasOnlyOnePublisher) {
      history.push(formatURL(publishers[0].publisher_id))
    }
  }, [publishers, hasOnlyOnePublisher, rmid])

  const options = useMemo(() => {
    if (!publishers || !publishers.length) return []

    const formattedData = publishers?.map(item => ({
      label: item.publisher_name,
      value: item.publisher_id
    }))

    return formattedData
  }, [publishers])

  const defaultValue = useMemo(
    () => (rmid ? options.filter(item => item.value === rmid) : null),
    [rmid, options]
  )

  const handleChange = useCallback(
    (data: ChangeProps) => {
      onChange?.(data)

      history.push(formatURL(data.value))
    },
    [onChange]
  )

  return (
    <Panel
      title={t('rm:Publisher')}
      description={description}
      className="form-grid"
      iconLeft={<BadgeIsValid isValid={!!defaultValue?.length} />}
      isCollapsible={isCollapsible}
    >
      <FormGroup className="span2">
        <SelectSearchable
          placeholder={t('rm:campaign.create.publisherplaceholder')}
          name="publisher_id"
          options={options}
          onChange={handleChange}
          isClearable={false}
          required
          defaultMenuIsOpen={!rmid}
          loading={loading}
          value={defaultValue}
        />
      </FormGroup>
    </Panel>
  )
}

export default PublishersFieldset
