import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacings.tiny};
    padding: ${theme.spacings.xxsmall} 0;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.colors.tertiary};
    }

    line-height: 1.125;

    background-color: ${theme.colors.white};

    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: ${theme.colors.light};
    }

    .disabled {
      opacity: 0.6;
    }

    .buttonToolTipChecked {
      border-color: #404256;
      .checkIcon {
        color: #404256;
      }
    }
  `}
`

export const Image = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
      margin: 0;
    }

    .without-image {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 3rem;
      height: 3rem;

      background-color: ${theme.colors.light};
      color: ${theme.colors.primaryLighten30};
    }
  `}
`

export const Infos = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
    margin-top: 0.25rem;

    > div {
      display: flex;

      &.mainInfo {
        font-size: ${theme.font.sizes.xsmall};
        align-items: center;
      }
    }

    .secondary {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;

      > * {
        margin: 0;
      }
    }
  `}
`

export const InfoItem = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;

    color: ${theme.colors.gray};
    font-size: ${theme.font.sizes.xsmall};

    transition: all 0.3s ease-in-out;

    flex: 1 1 100%;

    &:first-child {
      margin-right: 4px;
    }

    &:hover {
      color: ${theme.colors.primary};
    }

    * {
      font-size: ${theme.font.sizes.xsmall};
    }

    > svg {
      margin-right: ${theme.spacings.tiny};
    }
  `}
`

export const Action = styled.aside`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`

export const SelectedOnSearch = styled.div`
  .selectedItem {
    display: flex;
  }
  .removeItem {
    display: none;
  }

  ${Container}:hover & > {
    .selectedItem {
      display: none;
    }
    .removeItem {
      display: flex;
    }
  }
`
