import { useMemo } from 'react'
import { FiHome, FiPlus } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'

import { ButtonNavLink } from '~/components/Buttons'
import HeaderPages from '~/components/HeaderPages'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import { Badge } from '~/components/UI/Badge'
import { getRetailMediaAccountType, isAdvertiser } from '~/helpers'
import { useAppInfo } from '~/hooks/useAppInfo'
import useFetchSWR from '~/hooks/useFetchSWR'
import useQueryParams from '~/hooks/useQuery'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'

type WalletTransaction = {
  showActions?: boolean
  title?: string
  labelBreadcrumb?: string
  backTo?: string
}

const WalletHeader = ({
  showActions = true,
  title = t('rm:Wallet'),
  labelBreadcrumb = t('rm:backToWallets'),
  backTo = '/retail-media/wallets'
}: WalletTransaction) => {
  const { id } = useParams()
  const { hasRecharge } = useAppInfo()

  const accountRetailMediaId = useQueryParams().get('rmid')

  const accountType = getRetailMediaAccountType()
  const paramKeyName = accountType === 'publisher' ? 'advertiser' : 'publisher'

  const { data } = useFetchSWR<GetCheckingAccountWithAccountInfoResponse>({
    url: `${reatailmediaApi}/checking-account/${id}?account_info=true`,
    revalidateOnFocus: false
  })

  const accountInfo = useMemo(() => {
    const label = isAdvertiser() ? 'Publisher' : t('rm:Advertiser')
    const name =
      data?.[`${paramKeyName}_name`] ||
      (!data ? t('rm:Loading') : t('rm:noNameRegistered'))
    const logo = data?.[`${paramKeyName}_account_logo`]
    const bgColor =
      data?.[`${paramKeyName}_account_theme`]?.header_color || 'white'

    return { label, name, logo, bgColor }
  }, [data, paramKeyName])

  /**
   * Header props
   */

  const showRecharge = useMemo(
    () => showActions && isAdvertiser() && hasRecharge,
    [hasRecharge, showActions]
  )

  const headerProps = useMemo(
    () => ({
      title,
      backTo,
      labelBreadcrumb,
      subtitle: (
        <nav style={{ display: 'flex', gap: '.25rem', alignItems: 'center' }}>
          <Badge iconLeft={<FiHome />} style={{ height: '22px' }}>
            {accountInfo.label}: {accountInfo.name}
          </Badge>
          <ButtonNavLink
            to={`/retail-media/campaigns?rmid=${accountRetailMediaId}`}
            text={t('rm:seeCampaignDashboard')}
            size="xsmall"
            template="transparentPrimary"
            style={{ textDecoration: 'underline' }}
          />
        </nav>
      ),
      titleSideRightComponent: (
        <LogoStore
          size="large"
          alt={accountInfo.name}
          linkLogo={accountInfo.logo}
          backgroundLogo={accountInfo.bgColor}
        />
      ),
      sideRightComponent: showRecharge && (
        <ButtonNavLink
          to={`/retail-media/wallet/${id}/recharge?rmid=${accountRetailMediaId}`}
          text={t('rm:wallet.table.actions.addCredit')}
          iconLeft={<FiPlus />}
        />
      )
    }),
    [
      title,
      backTo,
      labelBreadcrumb,
      accountInfo,
      accountRetailMediaId,
      showRecharge,
      id
    ]
  )

  return (
    <>
      <PageTitle title={`${title} - ${accountInfo.name} - Retail media`} />

      <HeaderPages {...headerProps} />
    </>
  )
}

export default WalletHeader
