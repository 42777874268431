import React, { useCallback } from 'react'
import { FaBarcode } from 'react-icons/fa'
import {
  FiCheck,
  FiFolder,
  FiHash,
  FiPlus,
  FiTag,
  FiTrash,
  FiX
} from 'react-icons/fi'
import { LuListTree } from 'react-icons/lu'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

import AlertInfo from '~/components/AlertInfo'
import { Button } from '~/components/Buttons'
import ThumbZoomable from '~/components/ThumbZoomable'
import Tooltip from '~/components/Tooltip'
import { Badge } from '~/components/UI/Badge'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  addProductOnCampaign,
  deleteProductOnCampaign
} from '~/modules/retailMedia/store/campaign/actions'

import modelProduct from './model'

import * as S from './styles'

/**
 * Componente responsável por renderizar um produto nas listagens.
 * @param {Object} data Dados do produto
 * @param {boolean} isSelected Informa se o produto está na lista dos selecionados
 * @param {string[]} selectedSKUS Informa os SKUS selecionados para mudarmos o botão no painel de busca
 * @param {boolean} hasAction Informa se o produto está numa página de edição e deve exibir botões de ação
 */
const Product: React.FC<ProductProps> = ({
  data,
  isSelected = false,
  selectedSKUS = null,
  selectedIDs = null,
  hasAction = false,
  itemsDisableds = null
}) => {
  const dispatch = useDispatch()
  const { tCapitalize } = useAPPTranslation()

  const product = modelProduct(data)

  const isSelectedOnSearch =
    selectedSKUS?.find(item => item === data.product_sku) ||
    selectedIDs?.find(item => item === data.id)

  const handleAddProduct = useCallback(
    () => dispatch(addProductOnCampaign({ product: data })),
    [data, dispatch]
  )

  const handleDeleteProduct = useCallback(
    () => dispatch(deleteProductOnCampaign({ product: data })),
    [data, dispatch]
  )

  const isDisabled = itemsDisableds?.find(
    item => item.product_sku === data.product_sku || item.id === data.id
  )

  return (
    <S.Container>
      {!!product?.image && (
        <S.Image className={!!data.item_disabled && 'disabled'}>
          <ThumbZoomable size="tiny" url={product.image} title={product.name} />
        </S.Image>
      )}

      <S.Infos className={!!data.item_disabled && 'disabled'}>
        {!!product?.categories?.length && (
          <div className="secondary">
            <S.InfoItem>
              <FiFolder /> {product?.categories?.at(-1)}
            </S.InfoItem>
          </div>
        )}

        {product.name && (
          <div className="mainInfo">
            <div className="fw-bold fs-6">{product.name}</div>
          </div>
        )}

        <footer className="secondary">
          {product?.gtin?.map(item => (
            <Tooltip
              noMargin
              key={`gtin_${item}`}
              text="GTIN"
              icon={
                <Badge
                  size="tiny"
                  iconLeft={<FaBarcode />}
                  className="m-0"
                  text={item}
                />
              }
            />
          ))}

          {product.product_sku ? (
            <Tooltip
              noMargin
              text="SKU"
              icon={
                <Badge
                  size="tiny"
                  iconLeft={<FiTag />}
                  className="m-0"
                  text={product.product_sku}
                />
              }
            />
          ) : (
            <AlertInfo text={t('rm:ProductsPanel.skuNotFound')} />
          )}

          {product?.productReference && (
            <Tooltip
              noMargin
              text={t('rm:ProductsPanel.ProductReferenceCodes')}
              icon={
                <Badge
                  size="tiny"
                  iconLeft={<LuListTree />}
                  className="m-0"
                  text={product.productReference}
                />
              }
            />
          )}

          {product?.reference?.map(item => (
            <Tooltip
              noMargin
              key={`ref_${item}`}
              text={t('rm:ProductsPanel.AdditionalReferences')}
              icon={
                <Badge
                  size="tiny"
                  iconLeft={<FiHash />}
                  className="m-0"
                  text={item}
                />
              }
            />
          ))}
        </footer>
      </S.Infos>

      {hasAction && (
        <S.Action>
          {isSelectedOnSearch ? (
            <S.SelectedOnSearch className={!!isDisabled && 'not-hover'}>
              <Button
                template="primary"
                text={tCapitalize('c:words.selected')}
                iconLeft={<FiCheck />}
                className="selectedItem"
                size="xsmall"
                customWidth="auto"
              />

              <Button
                template="transparentDanger"
                text={tCapitalize('c:actions.remove')}
                iconLeft={<FiX />}
                onClick={handleDeleteProduct}
                className="removeItem"
                size="xsmall"
                customWidth="auto"
                disabled={!!isDisabled}
                name="removeItem"
              />
            </S.SelectedOnSearch>
          ) : (
            <>
              {isSelected ? (
                data.item_disabled ? (
                  <Tooltip
                    text={t('rm:ProductsPanel.alert.forbiddenRemoveProduct')}
                    icon={
                      <Button
                        template="ghost"
                        text={<FiCheck className="checkIcon" />}
                        size="xsmall"
                        customWidth="auto"
                        disabled={!!data.item_disabled}
                        className="buttonToolTipChecked disabled"
                        aria-label="Selected item"
                      />
                    }
                  />
                ) : (
                  <Button
                    template="transparentDanger"
                    text={<FiTrash />}
                    onClick={handleDeleteProduct}
                    size="xsmall"
                    customWidth="auto"
                    aria-label="Remove item"
                  />
                )
              ) : (
                <Button
                  template="primaryOutlined"
                  text={t('c:actions.select')}
                  iconLeft={<FiPlus />}
                  onClick={handleAddProduct}
                  size="xsmall"
                  customWidth="auto"
                />
              )}
            </>
          )}
        </S.Action>
      )}
    </S.Container>
  )
}

export default Product
