import { isPublisher } from '~/helpers'

import AdvertiserDashboardTabs from './Advertiser'
import PublisherDashboardTabs from './Publisher'

const MainTabs = () => {
  return isPublisher() ? (
    <PublisherDashboardTabs />
  ) : (
    <AdvertiserDashboardTabs />
  )
}

export default MainTabs
