import { useMemo } from 'react'
import { FaBarcode } from 'react-icons/fa'
import { FiHash, FiSearch, FiTag } from 'react-icons/fi'
import { LuListTree } from 'react-icons/lu'
import { PiStackPlusLight } from 'react-icons/pi'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'

import {
  getPublisherConfigurationKey,
  getPublisherConfigurationService
} from '../../services/configuration/publisher'

import * as S from './styles'

const ProductsPanelTabs: React.FC<ProductsPanelTabsProps> = ({
  activeTab,
  onChange,
  publisherId
}) => {
  const { data } = useQuery({
    queryKey: [getPublisherConfigurationKey, publisherId],
    queryFn: async () => {
      const response = await getPublisherConfigurationService(publisherId)

      return response?.data?.configuration?.catalog?.allowed_search_fields
    }
  })

  const tabs = useMemo(
    () =>
      [
        {
          id: 'search',
          icon: <FiSearch />,
          text: t('rm:filter.searchProducts'),
          isActive: activeTab === 'search'
        },
        {
          id: 'product_skus',
          icon: <PiStackPlusLight />,
          text: t('rm:ProductsPanel.AddInBulk'),
          isActive: activeTab !== 'search',
          isDisabled: !data?.length
        }
      ].filter(tab => !tab.isDisabled),
    [activeTab, data?.length]
  )

  const bulkTabs = useMemo(
    () =>
      [
        {
          id: 'product_skus' as SearchProductsFields,
          icon: <FiTag />,
          text: t('rm:ProductsPanel.Sku'),
          isActive: activeTab === 'product_skus'
        },
        {
          id: 'product_gtins' as SearchProductsFields,
          icon: <FaBarcode />,
          text: t('rm:ProductsPanel.Gtin'),
          isActive: activeTab === 'product_gtins'
        },
        {
          id: 'product_additional_references' as SearchProductsFields,
          icon: <FiHash />,
          text: t('rm:ProductsPanel.AdditionalReferences'),
          isActive: activeTab === 'product_additional_references'
        },
        {
          id: 'product_reference_codes' as SearchProductsFields,
          icon: <LuListTree />,
          text: t('rm:ProductsPanel.ProductReferenceCodes'),
          isActive: activeTab === 'product_reference_codes'
        }
      ].filter(tab => data?.includes(tab.id as SearchProductsFields)),
    [activeTab, data]
  )

  return (
    <>
      <S.Nav>
        {tabs.map(tab => (
          <Button
            key={tab.id}
            template={tab.isActive ? 'primary' : 'light'}
            onClick={() => onChange(tab.id as SearchProductBy)}
            text={tab.text}
            size="small"
            iconLeft={tab.icon}
            customWidth="auto"
          />
        ))}
      </S.Nav>

      {activeTab !== 'search' && (
        <S.Nav>
          {bulkTabs.map(tab => (
            <Button
              key={tab.id}
              template={tab.isActive ? 'primary' : 'light'}
              onClick={() => onChange(tab.id as SearchProductBy)}
              text={tab.text}
              size="xsmall"
              iconLeft={tab.icon}
              customWidth="auto"
            />
          ))}
        </S.Nav>
      )}
    </>
  )
}

export default ProductsPanelTabs
